import {
  Grid,
  Box,
  MenuItem,
  Typography,
  Stack,
  Button,
  TextField,
  InputAdornment,
  Tooltip,
  Link,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { ChooseClient } from "../../../../assets/icons";
import styles from "../index.module.css";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import {
  RecruiterList,
  MonthlyCommisions,
} from "../../../../services/apiRequests";
import { DataGrid } from "@mui/x-data-grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { ReactComponent as EmpTypes } from "../../../../assets/icons/account_tree.svg";
import useLoader from "../../../../Loader/useLoader";
import toast from "react-hot-toast";
import DateRangePickers from "../../../../CommonContainer/DateRangePicker";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setDashboardChild,
  setReportFilters,
  setscreenId,
} from "../../../../reduxApp/fieldStore";
import { Routepath } from "../../../../routing/routpath";
import MonthRangePicker from "../../../../CommonContainer/MonthRangePicker/MonthRangePicker";
import useAuth from "../../../../hooks/useAuth";

const CustomDashboard = ({
  name,
  options,
  value,
  HandleChange,
  label,
  access,
  labValue,
  iconData,
}) => {
  return (
    <TextField
      select
      value={value}
      name={name}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{iconData}</InputAdornment>
        ),
      }}
      sx={{ width: "100%" }}
      onChange={(e) => {
        HandleChange(e);
      }}
    >
      <MenuItem
        value="none"
        sx={{ display: name === "candidateStatus" ? "none" : "flex" }}
      >
        Select
      </MenuItem>
      {options
        .filter((obj) => obj[labValue] !== "SUB")
        .map((obj) => {
          return (
            <MenuItem
              key={obj[labValue]}
              value={obj[labValue]}
              disabled={
                access &&
                (obj[labValue] === "USA" || obj[labValue] === "Canada")
              }
            >
              {obj[label]}
            </MenuItem>
          );
        })}
    </TextField>
  );
};

const MonthlyCommision = ({
  extractedData,
  basicgetData,
  setBasicGetData,
  tabValue,
}) => {
  const [showLoader, hideLoader] = useLoader();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [zStyle, setXIndex] = useState("none");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [noOfCandidates, setnoOfCandidates] = useState({});
  const [goSelect, setGoSelect] = useState(false);
  const [RecruiterNames, setRecruiterList] = useState([]);
  const [countryRecruiter, setCountryRecruiter] = useState({});
  const [pageState, setPageState] = useState({
    page: 0,
    pageSize: 50,
  });
  const [dateRange, setDateRange] = useState({
    fromDate: null,
    toDate: null,
  });
  const { getAccess } = useAuth();
  const { noAccess: reportFilter } = getAccess("27");

  const [inputValue, setInputValue] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewMydetails = (userAction, id, userName) => {
    dispatch(setscreenId(3));
    dispatch(
      setDashboardChild({
        userAction: userAction,
        id: id,
        userName: userName,
      })
    );
    navigate(Routepath.RateCalculatorTabView);
  };

  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const header = useMemo(() => {
    let monthRange = [];
    const curretDate = dayjs();
    const start = new Date(startDate ?? curretDate);
    const end = endDate
      ? dayjs(endDate).add(30, "month")
      : dayjs(curretDate).add(30, "month");

    monthRange = [
      {
        id: 0,
        headerField: "candidateName",
        header: "Candidate Name",
      },
    ];

    while (start <= end) {
      monthRange.push({
        header: `${monthNames[start.getMonth()]} ${start.getFullYear()}`,
        headerField: `${start.getMonth() + 1}${start.getFullYear()}`,
      });
      start.setMonth(start.getMonth() + 1);
    }
    let rowDatas = monthRange.map((obj, ind) => {
      return {
        id: ind,
        headerName: obj.header,
        field: obj.headerField,
        width: 150,
        align: obj.headerField === "candidateName" ? "left" : "right",
        headerAlign: obj.headerField === "candidateName" ? "left" : "right",
        renderCell: (params) => {
          const formattedValue =
            params.value && obj.headerField !== "candidateName"
              ? basicgetData.country === "India"
                ? `₹ ${params.value || "0.00"}`
                : basicgetData.country === "CANADA"
                ? `$ ${params.value || 0.0}` //canada CA$
                : `$ ${params.value || 0.0}`
              : params.value;
          return obj.headerField === "candidateName" ? (
            params.row.candidateName !== "Total" ? (
              <Box sx={{ cursor: "pointer" }}>
                <Tooltip
                  InputProps={{ style: { fontSize: "24px" } }}
                  onClick={() =>
                    viewMydetails(
                      "View",
                      params.row.candidateDetailId,
                      params.row.candidateName
                    )
                  }
                >
                  <Link component="button" variant="h7">
                    {params.row.candidateName}
                  </Link>
                </Tooltip>
              </Box>
            ) : (
              <Box>
                <Typography variant="body2">
                  {params.row.candidateName}
                </Typography>
              </Box>
            )
          ) : (
            <Box sx={{ cursor: "pointer" }}>
              <Tooltip InputProps={{ style: { fontSize: "24px" } }}>
                <Typography variant="h7">{formattedValue}</Typography>
              </Tooltip>
            </Box>
          );
        },
      };
    });

    setRows(rowDatas);
  }, [goSelect]);

  const getLPKShorthand = (lookup, value) => {
    if (lookup) {
      const res = lookup?.find((obj) => obj.value === value);
      return res?.label;
    }
  };

  const getPaginationSizeOptions = (candidateCount) => {
    const defaultOptions = [50];
    if (candidateCount <= 50) {
      return defaultOptions;
    }
    const paginationOptions = [];
    const maxPageSize = Math.min(candidateCount, 100);
    for (let i = 50; i <= maxPageSize; i += 50) {
      paginationOptions.push(i);
    }
    return paginationOptions;
  };

  useMemo(async () => {
    try {
      showLoader();
      const res = await RecruiterList(basicgetData.role, basicgetData.country);
      if (res.status === 200) {
        setRecruiterList(res.data);
      }
    } catch (e) {
      //
    } finally {
      hideLoader();
    }
  }, [basicgetData.country, basicgetData.role]);

  const HandleChange = (e) => {
    const basicData = {
      ...basicgetData,
      [e.target.name]: e.target.value,
      startDate: startDate,
      endDate: endDate,
    };
    if (e.target.name === "role" || e.target.name === "country") {
      basicData.recruiterName = "none";
    }
    setBasicGetData(basicData);
  };

  const getDashboardDetails = async () => {
    try {
      showLoader();
      const payload = {
        recruiterId:
          basicgetData.recruiterName === "none"
            ? null
            : basicgetData.recruiterName,
        role: basicgetData.role === "none" ? null : basicgetData.role,
        country: basicgetData.country === "none" ? null : basicgetData.country,
        preId: basicgetData.preId === "none" ? null : basicgetData.preId,
        empType: basicgetData.empType === "none" ? null : basicgetData.empType,
        startDt: basicgetData.startDate ?? null,
        endDt: basicgetData.endDate ?? null,
        clientName:
          basicgetData.clientName === "none" ? null : basicgetData.clientName,
        bussinessUnintName:
          basicgetData.bussinessUnintName === "none"
            ? null
            : basicgetData.bussinessUnintName,
        page: pageState.page + 1,
        size: pageState.pageSize,
        sortBy: null,
        // startYear: dayjs(startDate).year(),
        // endYear: dayjs(endDate).year(),
        candidateStatus:
          basicgetData.candidateStatus === ""
            ? null
            : basicgetData.candidateStatus,
      };
      const res = await MonthlyCommisions(payload);
      if (res?.status === 200) {
        setnoOfCandidates({
          noOfApprovals: res.data?.noOfApprovals ?? "",
          noOfOnboarded: res.data?.noOfOnboarded ?? "",
          noOfDeclines: res.data?.noOfDeclines ?? "",
          totalMonthlyCommissionBonusCMP:
            res.data?.totalMonthlyCommissionBonusCMP ?? "",
          totalMonthlyCommissionBonusONB:
            res.data?.totalMonthlyCommissionBonusONB ?? "",
          totalMonthlyCommissionCMP: res.data?.totalMonthlyCommissionCMP ?? "",
          totalMonthlyCommissionONB: res.data?.totalMonthlyCommissionONB ?? "",
          role: res.data?.role ?? "",
          totalRecords: res.data?.totalRecords ?? "",
          monthlyCommissionIndia: res.data?.monthlyCommissionIndia ?? "",
          monthlyCommissionUsa: res.data?.monthlyCommissionUsa ?? "",
          monthlyCommissionCanada: res.data?.monthlyCommissionCanada ?? "",
          country: res.data?.recruiterCountry ?? "",
        });
        dispatch(setReportFilters(basicgetData));

        if (res.data?.candidateData?.length === 0 || res.data === "") {
          setColumns([]);
        } else {
          const setCoutryToId = {};
          res.data.candidateData?.forEach((obj) => {
            setCoutryToId[obj.applicationId] = obj.recruiterCountry;
          });
          setCountryRecruiter(setCoutryToId);
          const nestedLoop = res.data.candidateData?.map((objects, key) => {
            return { id: objects.applicationId, ...objects };
          });

          const totalCount = { candidateName: "Total", id: rows.length };

          rows.forEach((obj) => {
            if (obj.field !== "candidateName") {
              const counted = res.data.candidateData?.reduce(
                (total, current) => {
                  const value = current[obj.field];

                  // Ensure value is a valid number and not NaN or undefined
                  if (!isNaN(value) && value !== undefined && value !== null) {
                    return total + value;
                  }
                  return total;
                },
                0
              );

              // Safeguard the `counted` value and avoid `undefined` or other invalid outputs
              totalCount[obj.field] = counted ? counted.toFixed(2) : "0.00";
            }
          });

          addTorows(nestedLoop, totalCount);
        }
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    } finally {
      hideLoader();
    }
  };

  function addTorows(old, newRow) {
    old.push(newRow);
    setColumns(old);
  }

  const formatCurrency = (country, amount) => {
    const currencyMap = {
      India: { locale: "en-IN", currency: "INR" },
      CANADA: { locale: "en-CA", currency: "CAD" },
      USA: { locale: "en-US", currency: "USD" },
    };

    const { locale, currency } = currencyMap[country] || currencyMap["USA"];

    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(amount || 0.0);
  };

  const getTotalCommission = (country, cmp, onb) => {
    return cmp === 0.0
      ? formatCurrency(country, onb)
      : formatCurrency(country, cmp);
  };

  useEffect(() => {
    getDashboardDetails();
  }, [goSelect, pageState]);

  const handlePagination = (pages) => {
    setPageState({
      page: pages.page,
      pageSize: pages.pageSize,
    });
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ fromDate: startDate, toDate: endDate });
    const formattedStartDate = dayjs(startDate).format("MM-DD-YYYY");
    const formattedEndDate = dayjs(endDate).format("MM-DD-YYYY");
    setInputValue(`${formattedStartDate} - ${formattedEndDate}`);
  };

  const clearFilter = () => {
    setBasicGetData({
      bussinessUnintName: "none",
      clientName: "none",
      country: "none",
      empType: "none",
      recruiterName: "none",
      startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
      endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      role: "none",
      preId: null,
      candidateStatus: "CMP",
    });
  };

  const handleMonthRangeSelect = (startDate, endDate) => {
    const basicData = {
      ...basicgetData,
      startDate: startDate,
      endDate: endDate,
    };
    setBasicGetData(basicData);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    setStartDate(basicgetData.startDate ?? null);
    setEndDate(basicgetData.endDate ?? null);
  }, [basicgetData]);

  useEffect(() => {
    if (basicgetData.role !== "RCTR" && basicgetData.role !== "ACCMNGR") {
      setBasicGetData((prev) => ({
        ...prev,
        country: "none",
      }));
    }
  }, [basicgetData.role]);

  return (
    <Box>
      <Grid container spacing={3} pb={2}>
        <Grid item xs={4} sm={6} md={4}>
          <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
            Choose Role
          </Typography>

          <CustomDashboard
            labValue="value"
            label="label"
            iconData={<PermIdentityIcon />}
            options={extractedData?.ROLE ?? []}
            name="role"
            value={basicgetData.role}
            HandleChange={HandleChange}
          />
        </Grid>
        {(basicgetData.role === "RCTR" || basicgetData.role === "ACCMNGR") && (
          <Grid item xs={4} sm={6} md={4}>
            <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
              Choose Country
            </Typography>
            <CustomDashboard
              labValue="label"
              label="value"
              iconData={<PublicOutlinedIcon />}
              name="country"
              access={reportFilter}
              HandleChange={HandleChange}
              value={basicgetData.country}
              options={extractedData?.CALCOUNTRY ?? []}
            />
          </Grid>
        )}

        <Grid item xs={4} sm={6} md={4}>
          <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
            Choose Recruiter
          </Typography>
          <CustomDashboard
            labValue="id"
            label="name"
            iconData={<ChooseClient />}
            name="recruiterName"
            HandleChange={HandleChange}
            value={basicgetData.recruiterName}
            options={RecruiterNames}
          />
        </Grid>
        <Grid item xs={4} sm={6} md={4}>
          <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
            Choose Month Range
          </Typography>

          <MonthRangePicker
            maxDate={dayjs().endOf("month").toDate()}
            onSelect={handleMonthRangeSelect}
            defaultValue={[basicgetData.startDate, basicgetData.endDate]}
          />
        </Grid>
        <Grid item xs={4} sm={6} md={4}>
          <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
            Choose BUI
          </Typography>
          <CustomDashboard
            labValue="value"
            label="label"
            iconData={<EmpTypes />}
            name="bussinessUnintName"
            HandleChange={HandleChange}
            value={basicgetData.bussinessUnintName}
            options={extractedData?.BUSINESSUNIT ?? []}
          />
        </Grid>
        <Grid item xs={4} sm={6} md={4}>
          <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
            Choose Status
          </Typography>
          <CustomDashboard
            labValue="value"
            label="label"
            iconData={<CheckCircleIcon />}
            name="candidateStatus"
            HandleChange={HandleChange}
            value={
              basicgetData.candidateStatus === "SUB"
                ? "CMP"
                : basicgetData.candidateStatus
            }
            options={extractedData?.REPORTS_STATUS ?? []}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
        >
          <Button
            variant="outlined"
            sx={{
              marginTop: "0.5rem",
              textTransform: "capitalize",
              padding: "6px 26px",
              border: "1px solid #27282A ",
              color: "#27282A",
            }}
            onClick={clearFilter}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            sx={{
              marginTop: "0.5rem",
              backgroundColor: "#6E9FFF",
              color: "white",
              padding: "6px 26px",
              textTransform: "capitalize",
            }}
            onClick={() => {
              // if (basicgetData.country !== "none") {
              //   if (basicgetData.recruiterName === "none") {
              //     toast.error("Please Choose Recruiter");
              //     return;
              //   }
              // }
              // If no issues, proceed with fetching dashboard details
              setGoSelect(!goSelect);
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <Box sx={{ backgroundColor: "#68C06C" }} className={styles.colorBox}>
            <Typography sx={{ color: "white" }}>
              {noOfCandidates?.noOfApprovals === 0
                ? "No of OnBorded"
                : "No of Approval"}
            </Typography>
            <Grid
              sx={{
                color: "white",
                marginTop: "1rem",
                display: "flex",
                gap: "6px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              direction="row"
              spacing={2}
            >
              <CheckCircleOutlineIcon />

              <Typography
                sx={{
                  color: "white",
                  fontWeight: "Bolder",
                  fontSize: "1.2rem",
                }}
              >
                {noOfCandidates?.noOfApprovals === 0
                  ? noOfCandidates?.noOfOnboarded
                  : noOfCandidates?.noOfApprovals}{" "}
                Approved Candidate
              </Typography>
            </Grid>
          </Box>
        </Grid>
        {/* </Grid>
        <Grid item xs={3}> */}
        <Grid item md={6} sm={12}>
          <Box sx={{ backgroundColor: "#D8474E" }} className={styles.colorBox}>
            <Typography sx={{ color: "white" }}>No of Decline</Typography>
            <Grid
              sx={{
                color: "white",
                marginTop: "1rem",
                display: "flex",
                gap: "6px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              spacing={2}
            >
              <CancelOutlinedIcon />

              <Typography
                sx={{
                  color: "white",
                  fontWeight: "Bolder",
                  fontSize: "1.2rem",
                }}
              >
                {noOfCandidates?.noOfDeclines} Decline Candidate
              </Typography>
            </Grid>
          </Box>
        </Grid>
        {/* </Grid>
        <Grid item xs={3}> */}
        <Grid item md={6} sm={12}>
          <Box
            sx={{
              backgroundColor: "#948DB9",
            }}
            className={styles.colorBox}
          >
            <Tooltip
              title={
                basicgetData.country === "India"
                  ? "Monthly Commission Bonus"
                  : basicgetData.country === "none"
                  ? "Monthly Commission Bonus / Monthly Commission"
                  : "Monthly Commission "
              }
            >
              <Typography sx={{ color: "white" }}>
                {basicgetData.country === "India"
                  ? "Monthly Commission Bonus"
                  : basicgetData.country === "none"
                  ? "Monthly Commission Bonus / Monthly Commission"
                  : "Monthly Commission "}
              </Typography>
            </Tooltip>
            <Grid
              sx={{
                color: "white",
                marginTop: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
              direction="row"
              spacing={2}
            >
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "1.2rem",
                  alignItems: "center",
                }}
              >
                {basicgetData.country === "India"
                  ? new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(noOfCandidates?.monthlyCommissionIndia)
                  : basicgetData.country === "USA"
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(noOfCandidates?.monthlyCommissionUsa)
                  : basicgetData.country === "Canada"
                  ? new Intl.NumberFormat("en-CA", {
                      style: "currency",
                      currency: "CAD",
                    }).format(noOfCandidates?.monthlyCommissionCanada) +
                    "(Canada)"
                  : [
                      new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(noOfCandidates?.monthlyCommissionIndia || 0),

                      new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(noOfCandidates?.monthlyCommissionUsa || 0),

                      new Intl.NumberFormat("en-CA", {
                        style: "currency",
                        currency: "CAD",
                      }).format(noOfCandidates?.monthlyCommissionCanada || 0) +
                        "(Canada)",
                    ].join(" / ")}

                {/* {noOfCandidates.country === "India"
                  ? getTotalCommission(
                      noOfCandidates.country,
                      noOfCandidates.totalMonthlyCommissionBonusCMP,
                      noOfCandidates.totalMonthlyCommissionBonusONB
                    )
                  : getTotalCommission(
                      noOfCandidates.country,
                      noOfCandidates.totalMonthlyCommissionCMP,
                      noOfCandidates.totalMonthlyCommissionONB
                    )} */}
              </Typography>
            </Grid>
          </Box>
        </Grid>
        {/* </Grid>
        <Grid item xs={3}> */}
        <Grid item md={6} sm={12}>
          <Box sx={{ backgroundColor: "#E97CDF" }} className={styles.colorBox}>
            <Typography sx={{ color: "white" }}>Designation</Typography>
            <Grid
              sx={{
                color: "white",
                marginTop: "1rem",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              direction="row"
              spacing={2}
            >
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "Bolder",
                  fontSize: "1.2rem",
                }}
              >
                {basicgetData?.role === "none"
                  ? "--"
                  : getLPKShorthand(extractedData?.ROLE, basicgetData?.role)}
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* </Grid>
      </Grid> */}
      {
        <DataGrid
          rows={columns ?? []}
          columns={rows ?? []}
          rowCount={noOfCandidates?.totalRecords}
          paginationMode="server"
          paginationModel={pageState}
          pageSizeOptions={getPaginationSizeOptions(
            noOfCandidates?.totalRecords
          )}
          onPaginationModelChange={handlePagination}
          autoHeight
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-cell:first-of-type": {
              fontWeight: "700",
              fontSize: "1.1em",
            },
            marginTop: "1rem",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "0.9rem",
              fontWeight: "700",
              color: "#000000",
            },
            "& .MuiDataGrid-row": {
              color: "black",
              textAlign: "center",
              borderRight: "none",
            },
            "& .MuiDataGrid-row:last-of-type": {
              backgroundColor: "#E2E2E4",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderRight: "0.5px solid #EFEBD8",
            },
            "& .MuiDataGrid-cell": {
              borderRight: "0.5px solid #EFEBD8",
            },
          }}
        />
      }
    </Box>
  );
};

export default MonthlyCommision;
