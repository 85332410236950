import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import { Routepath } from "../../../routing/routpath";
import TextFields from "../../../CommonContainer/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Dropdown } from "../../../CommonContainer/DropDown";
import DateField from "../../../CommonContainer/DateField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import {
  groupLookup,
  pairWithValue,
  pairWithLabels,
} from "../../../utils/helpers";
import TwoTextFields from "../../../CommonContainer/TwoTextField";
import { getLookup, activityDataSave } from "../../../services/apiRequests";
import {
  getVmsDataByPagesandRows,
  updateCandidateById,
  getCandidateById,
  submitNotification,
} from "../../../services/apiRequests";
import useAuth from "../../../hooks/useAuth";
import { dashboard_api } from "../../../constance/DashboardJson";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty } from "lodash";
import dayjs from "dayjs";
import { database_List } from "../../../constance/DashboardJson";
import { errorMsg } from "../../../constance/validityItems";
import { setNotificationDetails } from "../../../reduxApp/fieldStore";
import useLoader from "../../../Loader/useLoader";
import NumberTextFields from "../../../CommonContainer/numberTextField";
import {
  Box,
  Button,
  Grid,
  Collapse,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Icon,
} from "@mui/material";
import { Textarea, FormHelperText } from "@mui/joy";
import { MarkUpTable } from "../../../CommonContainer/MarkUpTable";
import { B2BIMariginLimits } from "../../../CommonContainer/B2BIMariginLimits";
import { PayBenefits } from "../../../CommonContainer/PayBenefits";
import { useScreenId } from "../../../hooks/useScreenId";

import toast from "react-hot-toast";
import AreYouSureModel from "../../../CommonContainer/AreYouSureModel";
import SucessModel from "../../../CommonContainer/SucessModel";

const AccountExeContent = () => {
  const navigate = useNavigate();
  const [wholeLookup, setWholeLookUp] = useState({});
  const [declineModal, setDeclineModal] = useState(false);
  const [errorText, setErrorText] = useState({});
  const [candidateDetails, setCandidateDetails] = useState({});
  const [openDelete, setOpenDelete] = useState({
    screenId: -1,
    openDelete: false,
  });
  const candidateList = useSelector((state) => state.dropedField);
  const employIntegration = useSelector((state) => {
    return state.dropedField;
  });
  const { getAccess } = useAuth();
  const { noAccess: totalComoer24 } = getAccess("10");
  const { noAccess: reMntlyCmsPH } = getAccess("11");
  const { noAccess: tcmnsPH } = getAccess("12");
  const { noAccess: hrlyRate } = getAccess("13");
  const { noAccess: Boarding } = getAccess("15");
  const { noAccess: termination } = getAccess("16");
  const { noAccess: calcCountry } = getAccess("17");
  const [calCountry, setCalcCountry] = useState();
  const [vmsForBu, setvmsForBu] = useState();
  const [lookupGroup, setLookupGroup] = useState({});
  const [VMSFee, setVmsFee] = useState([]);
  const [apiIntegrated, setApiIntegrated] = useState(false);
  const dispatch = useDispatch();
  const [showLoader, hideLoader] = useLoader();
  const [approved, setApproved] = useState(false);
  const [returned, setReturned] = useState(false);
  const currentTime = new Date();
  const visibleAccess = {
    recuiterMonthlyCommissionPerHour: totalComoer24,
    totalCommissionForMonthPro: reMntlyCmsPH,
    recruiterMonthlyCommissionPro: tcmnsPH,
    hourlyRate: hrlyRate,
    onboardingDt: Boarding,
    terminationDt: termination,
    calculationCountry: calcCountry,
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setApproved(false);
  };
  const handleOpenReturn = () => {
    setReturned(true);
  };

  const handleCloseReturn = () => {
    setReturned(false);
  };

  const denide = () => {
    saveAndpost("Denied");
  };
  const handleApprove = () => {
    saveAndpost("Approved");
  };
  const returndata = () => {
    saveAndpost("Returned");
  };

  useScreenId(
    6,
    () => {
      getNotificationTables();
    },
    [],
    [apiIntegrated]
  );
  const getNotificationTables = async () => {
    try {
      showLoader();
      if (
        employIntegration.Notification.Event === "View" ||
        employIntegration.Notification.Event === "Edit"
      ) {
        let response = await getCandidateById(
          "getbycandidateid",
          employIntegration.Notification.id
        );
        const res = await getLookup();
        const vmsCompany = (await getVmsDataByPagesandRows(0, 1000)).data;
        const extractedData = map(
          vmsCompany.content,
          ({ customerNameWithFee, feePercent, vmsId }) => ({
            label: customerNameWithFee,
            value: vmsId,

            // id: vmsId,
          })
        );
        const BuforVms = vmsCompany.content.reduce((acc, current) => {
          if (acc[current.bussinessUnit]) {
            acc[current.bussinessUnit].push({
              label: current.customerNameWithFee,
              value: current.vmsId,
              markup: current.allowedMarkupPct,
            });
          } else {
            acc[current.bussinessUnit] = [
              {
                label: current.customerNameWithFee,
                value: current.vmsId,
                markup: current.allowedMarkupPct,
              },
            ];
          }
          return acc;
        }, {});

        setvmsForBu(BuforVms);
        const vmsArray = { VMS: extractedData };
        if (res.status === 200) {
          const groupedLookup = groupLookup(res.data);
          const vmsLookup = groupLookup(extractedData);
          setLookupGroup(groupedLookup);
          const vmsFor = response.hasOwnProperty("candidateDetailId")
            ? BuforVms[response.candidateBusinessUnit]
              ? BuforVms[response.candidateBusinessUnit]
              : []
            : extractedData;
          setVmsFee({ VMS: vmsFor });

          let tempData = { ...response.data };
          tempData["country"] = employIntegration.loginData.country;
          tempData["recuriterId"] = employIntegration.loginData.id;
          setCandidateDetails({ ...tempData, comments: "" });
        }
      }
    } catch (error) {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };
  const checkMyvalidity = () => {
    let isAllValid = true;
    let targetDiv = "";
    let errorList;
    if (candidateDetails.comments) {
      errorList = "";
      isAllValid = true;
    } else {
      errorList = { comments: "Please add comments" };
      isAllValid = false;
    }
    return {
      isAllValid: isAllValid,
      targetDiv: targetDiv,
      errorList: errorList,
    };
  };
  const saveAndpost = async (userActions) => {
    try {
      const { isAllValid, targetDiv, errorList } = checkMyvalidity();

      if (isAllValid) {
        showLoader();

        let tempData = {
          ...candidateDetails,
        };

        tempData["country"] = employIntegration.loginData.country;
        tempData["loginUserId"] = employIntegration.loginData.id;
        // const { isAllValid, targetDiv, errorList } = checkMyvalidity();

        tempData.candidateStatus = userActions;

        const res = await submitNotification(
          candidateDetails.applicationId,
          tempData
        );
        if (res.status === 200) {
          setOpen(false);
          setApproved(false);
          navigate(Routepath.DashboardTabsAll);
        }
        setCandidateDetails({ ...dashboard_api });
        setErrorText({});
      } else {
        setErrorText(errorList);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  function setBackTodashboard() {
    setCandidateDetails({ ...dashboard_api });
    navigate(Routepath.DashboardTabsAll);
    dispatch(
      setNotificationDetails({
        Event: "",
        id: "",
      })
    );
  }

  useMemo(() => {
    setCalcCountry(candidateDetails.calculationCountry);
  }, [candidateDetails]);

  function HandleChange(e, name, Vmsvalue) {
    if (name === "calculationCountry") {
      setCalcCountry(e);
    }
    let wholeFields = {
      ...candidateDetails,
      [name]: name === "candidateStartDt" ? dayjs(e).format("YYYY-MM-DD") : e,
      [name]: name === "comments" ? e : e,
    };
    errorText.hasOwnProperty(name) &&
      name !== "candidateStartDt" &&
      setErrorText({
        ...errorText,
        [name]: e === "" ? errorMsg[name] : "",
      });
    if (name === "vmsFee" && candidateDetails.candidateBusinessUnit) {
      const getMarkup = vmsForBu[candidateDetails.candidateBusinessUnit].find(
        (obj) => obj.label === e
      );
      const markupValue = getMarkup?.markup;
      wholeFields["allowedMarkupPct"] = markupValue
        ? markupValue === "NA"
          ? "No Markup"
          : markupValue
        : "--";
    }

    if (name === "candidateBusinessUnit") {
      const vmsFor = vmsForBu[e] ? vmsForBu[e] : [];
      wholeFields.vmsFee = "";
      wholeFields["allowedMarkupPct"] = "";
      setVmsFee({ VMS: vmsFor });
    }
    setCandidateDetails(wholeFields);
    // if (name === "candidateCountry") {
    //   setAlignment(e.target.value);
    // }
  }

  const displayRules = (name) => {
    let BooleanResult = true;

    if (["Allowance"].includes(name)) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (["Total Commission for # months"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (name === "leaveStateCd") {
      candidateList.candidateCountry === "USA"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (["calculationCountry"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (["recMnthlyCommissionBonus"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = true)
        : (BooleanResult = false);
    }
    if (
      [
        "provinceState",
        "statutoryHolidays",
        "vacationPayHourly",
        "vacationPayAnnual",
        "optionalHolidays",
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionUSDVal",
      ].includes(name)
    ) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = true)
        : (BooleanResult = false);
    }
    if (
      ["totalCommissionForMonthPro", "recruiterMonthlyCommissionPro"].includes(
        name
      )
    ) {
      candidateList.loginData.country == "India"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (
      [
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionUSDVal",
      ].includes(name)
    ) {
      if (
        candidateDetails.candidateCountry === "CAD" &&
        candidateList.loginData.country === "USA"
      ) {
        BooleanResult = true;
      } else BooleanResult = false;
    }
    if (
      [
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionUSDVal",
      ].includes(name)
    ) {
      if (
        candidateDetails.candidateCountry === "CAD" &&
        candidateList.loginData.country === "India"
      ) {
        BooleanResult = false;
      }
    }
    if (
      [
        "vacationNoOfDays",
        "candidateInsurenceType",
        "holidays",
        "immgirationType",
        "otherExpensesHourly",
        "totalCommissionForMonthPro",
        "recruiterMonthlyCommissionPro",
        "hourlyRate",
        "perDiemCount",
        "perDiem",
        "payRatePerDiem",
        "recruiterMonthlyCommission",
        "recuiterMonthlyCommissionPerHour",
      ].includes(name)
    ) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    return BooleanResult;
  };

  const setMydivName = (name, divName) => {
    let myDivName = "";
    if (candidateDetails.candidateCountry === "CAD") {
      switch (name) {
        case "recuiterMonthlyCommission": {
          myDivName = "Recruiter Monthly Commission(CAD)";
          break;
        }
        case "totalCommissionFor24Months": {
          myDivName = "Total Commission for # months (CAD)";
          break;
        }
        default:
          myDivName = divName;
      }
    } else myDivName = divName;
    return myDivName;
  };

  const chooseDropdoenOptions = (type) => {
    let filteredOption = lookupGroup[type];
    if (type === "RESOURCETYPE") {
      if (candidateDetails.candidateCountry === "CAD") {
        const filteredOptions = lookupGroup["RESOURCETYPE"].filter(
          (key) => key.label === "T4"
        );

        filteredOption = filteredOptions;
      } else {
        const filteredOptions = lookupGroup["RESOURCETYPE"].filter(
          (key) => key.label !== "T4"
        );

        filteredOption = filteredOptions;
      }
    }
    return filteredOption;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
          minHeight: "90vh",
          paddingTop: "3%",
          alignItems: "top",
          //gap: "100px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            width: "630px",
            rowGap: "10px",
            padding: "0px 0px 19px 0px",
          }}
        >
          {!isEmpty(candidateDetails) && !isEmpty(lookupGroup) && (
            <>
              {database_List.map((item, key) => {
                if (item.field === "Textfield") {
                  return (
                    <TextFields
                      id={`C${key}`}
                      displayRule={displayRules(item.name)}
                      currency={
                        [
                          // "recruiterMonthlyCommissionPro",
                          // "recuiterMonthlyCommissionPerHour",
                          // "totalCommissionForMonthPro",
                          // "hourlyRate",
                          "recMnthlyCommissionBonus",
                          "recuiterMonthlyCommission",
                        ].includes(item.name)
                          ? candidateList.loginData.roleCd != "RCTR" ||
                            candidateList.loginData.roleCd != "ACCMNGR"
                            ? calCountry === "India"
                              ? "IND"
                              : calCountry === "USA"
                              ? "USA"
                              : candidateList.loginData.country === "India"
                              ? "IND"
                              : candidateList.loginData.country === "USA"
                              ? "USA"
                              : "CAD"
                            : ""
                          : "USA"
                      }
                      display={
                        [
                          "recruiterMonthlyCommissionPro",
                          "recuiterMonthlyCommissionPerHour",
                          "totalCommissionForMonthPro",
                          "hourlyRate",
                        ].includes(item.name)
                          ? !visibleAccess[item.name]
                          : true
                      }
                      isRequired={item?.mandatory ?? false}
                      defaultValue={candidateDetails[item.name]}
                      onChange={HandleChange}
                      names={item.name}
                      defaultVlues={item.defaultVlues}
                      corticonField={item?.corticonField ?? false}
                      errorText={
                        errorText.hasOwnProperty(item.name)
                          ? errorText[item.name]
                          : ""
                      }
                      Action={employIntegration.Notification.Event}
                      fieldName={setMydivName(item.name, item.divName)}
                      colorind={candidateDetails.colorInd}
                    />
                  );
                } else if (item.field === "dropDown") {
                  return (
                    <Dropdown
                      displayRule={displayRules(item.name)}
                      defaultValue={candidateDetails[item.name]}
                      isRequired={item?.mandatory ?? false}
                      onChange={HandleChange}
                      id={`C${key}`}
                      defaultVlues={item.defaultVlues}
                      fieldName={item.divName}
                      name={item.name}
                      errorText={
                        errorText.hasOwnProperty(item.name)
                          ? errorText[item.name]
                          : ""
                      }
                      corticonField={item?.corticonField ?? false}
                      options={
                        item.lookupType === "VMS"
                          ? VMSFee[item.lookupType]
                          : chooseDropdoenOptions([item.lookupType])
                      }
                      action={employIntegration.Notification.Event}
                      display={
                        ["calculationCountry"].includes(item.name)
                          ? !visibleAccess[item.name]
                          : true
                      }
                    ></Dropdown>
                  );
                } else if (item.field === "Datefield") {
                  return (
                    <DateField
                      defaultValue={candidateDetails[item.name]}
                      isRequired={item?.mandatory ?? false}
                      id={`C${key}`}
                      Action={employIntegration.Notification.Event}
                      onChange={HandleChange}
                      errorText={
                        errorText[item.name] ? errorText[item.name] : ""
                      }
                      option={item}
                      fieldName={item.divName}
                      name={item.name}
                      display={
                        ["onboardingDt", "terminationDt"].includes(item.name)
                          ? !visibleAccess[item.name]
                          : true
                      }
                    ></DateField>
                  );
                } else if (item.field === "TwoText") {
                  return (
                    <TwoTextFields
                      displayRule={
                        displayRules(item.divName)
                        // candidateDetails.candidateCountry === "CANADA"
                        //   ? item.divName === "Allowance"
                        //     ? false
                        //     : true
                        //   : true
                      }
                      isRequired={
                        item.divName === "Recruiter Name" ? false : true
                      }
                      id={`C${key}`}
                      Action={employIntegration.Notification.Event}
                      // defaultVlues={item.defaultVlues}
                      onChange={HandleChange}
                      country={calCountry ?? candidateList.loginData.country}
                      errorTextFirst={
                        errorText[item.fName] ? errorText[item.fName] : ""
                      }
                      errorTextLast={
                        errorText[item.lName] ? errorText[item.lName] : ""
                      }
                      // display={
                      //   candidateDetails.recruiterCountry === "India"
                      //     ? [
                      //         "Allowance",
                      //         "Total Commission for # months",
                      //       ].includes(item.divName)
                      //       ? false
                      //       : true
                      //     : true
                      // }
                      FistcorticonField={item?.FistcorticonField ?? false}
                      LastcorticonField={item?.LastcorticonField ?? false}
                      fieldName={item.divName}
                      RFname={candidateDetails[item.fName]}
                      RLname={candidateDetails[item.lName]}
                      fName={item.fName}
                      lName={item.lName}
                    />
                  );
                } else if (item.field === "numberField") {
                  return (
                    <NumberTextFields
                      corticonField={item?.corticonField ?? false}
                      defaultValue={candidateDetails[item.name]}
                      isRequired={item?.mandatory ?? false}
                      id={`C${key}`}
                      names={item.name}
                      onChange={HandleChange}
                      errorText={
                        errorText.hasOwnProperty(item.name)
                          ? errorText[item.name]
                          : ""
                      }
                      Action={employIntegration.Notification.Event}
                      fieldName={item.divName}
                      currency={candidateDetails.candidateCountry}
                    />
                  );
                }
              })}
              {/* {employIntegration.Notification.Event !== "View" && ( */}
              <Box id="comments">
                <Grid
                  container
                  sx={{
                    lineHeight: "3.5",
                    alignItems: "center",
                    marginBottom: "5px",
                    fontWeight: "600",
                    wrap: "noWrap",
                    fontSize: "14px",
                  }}
                >
                  <Box
                    sx={{
                      color: "var(--Black, var(--Text, #000))",
                      textAlign: "right",
                      fontSize: "14px",
                      padding: "20px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                      width: "195px",
                    }}
                    id={`c${database_List.length}`}
                  >
                    Comments<span style={{ color: "red" }}>*</span>
                  </Box>

                  <Textarea
                    className="defaultFieldStruc"
                    minRows={3}
                    placeholder={
                      errorText.hasOwnProperty("comments")
                        ? errorText.comments
                        : ""
                    }
                    error={
                      errorText.hasOwnProperty("comments")
                        ? errorText.comments
                        : ""
                    }
                    names="comments"
                    onChange={(e) => HandleChange(e.target.value, "comments")}
                  />
                </Grid>
              </Box>
              {/* )} */}
              <Box style={{ display: "flex", justifyContent: "end" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "170px",
                  }}
                >
                  <>
                    {!["PEN", "APL1", "RET"].includes(
                      candidateDetails?.candidateStatus
                    ) ? (
                      <div style={{ display: "flex", gap: "20px" }}>
                        <div>
                          <Button
                            variant="contained"
                            className="btnLH repeateBtn"
                            disabled={
                              employIntegration.notoficatioActionAcs === "RO"
                            }
                            onClick={() => {
                              {
                                if (candidateDetails.comments) {
                                  setErrorText({ comments: "" });

                                  handleOpenReturn();
                                } else {
                                  setErrorText({
                                    comments: "Please add comments",
                                  });
                                }
                              }
                            }}
                          >
                            <Icon style={{ marginRight: "4px" }}>
                              {<LoopOutlinedIcon />}
                            </Icon>
                            Return
                          </Button>
                        </div>
                        <Button
                          variant="contained"
                          className="btnLH declineBtn"
                          disabled={
                            employIntegration.notoficatioActionAcs === "RO"
                          }
                          onClick={() => {
                            // saveAndpost("Denied")
                            if (candidateDetails.comments) {
                              setErrorText({ comments: "" });
                              handleClickOpen();
                            } else {
                              setErrorText({ comments: "Please add comments" });
                            }
                          }}
                        >
                          <icon
                            style={{
                              marginRight: "6px",
                              verticalAlign: "bottom",
                            }}
                          >
                            <ThumbDownAltOutlinedIcon />
                          </icon>
                          Decline
                        </Button>
                        <Button
                          variant="contained"
                          className="btnLH acceptBtn"
                          disabled={
                            employIntegration.notoficatioActionAcs === "RO"
                          }
                          onClick={() => {
                            if (candidateDetails.comments) {
                              setErrorText({ comments: "" });
                              setApproved(true);
                            } else {
                              setErrorText({ comments: "Please add comments" });
                            }
                          }}
                        >
                          <Icon style={{ marginRight: "6px" }}>
                            <ThumbUpAltOutlinedIcon />
                          </Icon>
                          {candidateDetails?.candidateStatus === "CMP" ||
                          candidateDetails?.candidateStatus === "ONB"
                            ? "On Board"
                            : "Approve"}
                        </Button>
                      </div>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate(Routepath.DashboardTabsAll);
                        }}
                      >
                        Back
                      </Button>
                    )}
                  </>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            position: "sticky",
            height: "96vh",
            overflow: "auto",
            right: "10px",
            top: "100px",
          }}
        >
          <Box sx={{ border: "1px solid gray", borderRadius: " 0 0 4px 4px" }}>
            <Accordion
              sx={{
                display:
                  candidateDetails?.rtMarkUpModel == null ? "none" : "block",

                // paddingTop: "30px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ background: "#a3a3a39c" }}
              >
                <Typography sx={{ fontWeight: "600", color: "black" }}>
                  Mark Up List
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: "flex" }}>
                  <MarkUpTable value={candidateDetails?.rtMarkUpModel} />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                display:
                  candidateDetails?.prFinalRateModel == null ? "none" : "block",
                // paddingTop: "30px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ background: "#a3a3a39c" }}
              >
                <Typography sx={{ fontWeight: "600", color: "black" }}>
                  Pay Benefits
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: "flex" }}>
                  <PayBenefits value={candidateDetails?.prFinalRateModel} />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                display:
                  candidateDetails?.rtMarkUpModel == null ? "none" : "block",
                // paddingTop: "30px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ background: "#a3a3a39c", borderRadius: " 0 0 4px 4px" }}
              >
                <Typography sx={{ fontWeight: "600", color: "black" }}>
                  B2BI Margin Limit
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!isEmpty(lookupGroup) && (
                  <div style={{ display: "flex" }}>
                    <B2BIMariginLimits value={lookupGroup} />
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        {open && (
          <AreYouSureModel
            open={open}
            handleClose={handleClose}
            HandleCancel={handleClose}
            HandleDelete={denide}
          />
        )}
        {approved && (
          <SucessModel
            open={approved}
            handleApprove={handleApprove}
            handleClose={handleClose}
            words={
              candidateDetails?.candidateStatus === "CMP"
                ? "On Board"
                : "Approve"
            }
          />
        )}
        {}
        {
          <Dialog
            open={returned}
            onClose={handleCloseReturn}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Return Application"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do You want to Return this Application?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseReturn} autoFocus>
                No
              </Button>
              <Button onClick={() => saveAndpost("Returned")}>Yes</Button>
            </DialogActions>
          </Dialog>
        }
      </Box>
    </>
  );
};
export default AccountExeContent;
