export const errorMsg = {
  candidateName: "Enter Candidate Name",
  candidateJobTitle: "Enter Candidate JobTitle",
  candidateStartDt: "Enter Candidate Start Date",
  // recuiterFirstName: "Enter Recruiter First Name",
  // recuiterLastName: "Enter Recruiter Last Name",
  vacationNoOfDays: "Enter No of  Vacation Days ",
  sickLeaveNoOfDays: "Enter No of sick leave",
  otherExpensesHourly: "Enter Hourly Other expenses",
  onboardingDt: "Select on boarding Date",
  otherExpenses: "Enter Other expenses",
  payRate: "Enter Pay Rate",
  refferalAmount: "Enter Refferal Amount",
  candidateBillRate: "Enter Bill Rate",
  perDiem: "Enter Per Diem",
  recuiterMonthlyCommissionPerHour: "Enter Recriter total commission Per Hour",
  recuiterTotCommissionForMonths: "Enter Recriter total commission for Months",
  preId: "Enter valid preId",
  perDiemCount: "Enter valid Allowance",
  totalCommissionFor24Months: " Enter Valid Months",
  comments: "Enter commnets",
  leaveStateCd: " Choose State",
  provinceState: "Choose Province State",
};
export const dashboard_Errormsg = {
  candidateName: "",
  candidateJobTitle: "",
  candidateStartDt: "",
  candidateBusinessUnit: "", //canot
  candidateCountry: "",
  provinceState: "",
  // recuiterFirstName: "",
  // recuiterLastName: "",
  tnVisaCountry: "",
  tnVisaPremiumYN: "",
  onboardingDt: "",
  preId: "",
  candidateTypeOfResource: "",
  candidateBillRate: "",
  vmsFee: "",
  vacationNoOfDays: "",
  sickLeaveNoOfDays: "",
  holidays: "",
  candidateInsurenceType: "", //canot
  refferalAmount: "",
  immgirationType: "", //canot
  otherExpenses: "",
  otherExpensesHourly: "", //canot
  jobId: "",
  perDiemCount: "", //canot
  leaveStateCd: "",
  payRate: "",
  totalCommissionFor24Months: "",
};

export const calculate_Errormsg = {
  // candidateName: "",
  // candidateJobTitle: "",
  // candidateStartDt: "",
  candidateBusinessUnit: "", //canot
  candidateCountry: "",
  recuiterFirstName: "",
  recuiterLastName: "",
  tnVisaCountry: "",
  tnVisaPremiumYN: "",
  onboardingDt: "",
  preId: "",
  candidateTypeOfResource: "",
  candidateBillRate: "",
  vmsFee: "",
  vacationNoOfDays: "",
  sickLeaveNoOfDays: "",
  holidays: "",
  candidateInsurenceType: "", //canot
  refferalAmount: "",
  immgirationType: "", //canot
  otherExpenses: "",
  otherExpensesHourly: "", //canot
  // jobId: "",
  perDiemCount: "", //canot
  // leaveStateCd: "",
  payRate: "",
  totalCommissionFor24Months: "",
};

export const dashboard_Errormsg_submit = {
  candidateName: "",
  candidateJobTitle: "",
  candidateStartDt: "",
  candidateBusinessUnit: "", //canot
  candidateCountry: "",
  // recuiterFirstName: "",
  onboardingDt: "",
  // recuiterLastName: "",
  preId: "",
  candidateTypeOfResource: "",
  candidateBillRate: "",
  vmsFee: "",
  vacationNoOfDays: "",
  sickLeaveNoOfDays: "",
  holidays: "",
  candidateInsurenceType: "", //canot
  refferalAmount: "",
  immgirationType: "", //canot
  otherExpenses: "",
  otherExpensesHourly: "", //canot
  jobId: "",
  perDiemCount: "", //canot
  payRate: "",
  totalCommissionFor24Months: "",
  tnVisaCountry: "",
  tnVisaPremiumYN: "",
};
